$red: #e91b1b;
$redActive: #db5454;

$navbar: #102446;
$search: #37404b;
$addButton: #4582ff;
$addButtonActive: #a3b8e4;

$blueReserve: #102446;
$blueActiveReserve: #8eace0;

$blue: #434c57;
$miniBlue: #8eace0;
$blueActive: #37404b;

$green: #479741;
$greenActive: #83c77e;

$gray: #b8b6b6;
$white: #ffffff;

$background: #e7eaee;
$whiteActive: #d9e0eb;

$tableHeader: #f2f4f3;


