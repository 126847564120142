@use './size.scss' as *;
@use './colors.scss' as *;

.side-bar {
    top: 0%;
    left: 0px;
    z-index: 10;
    width: 250px;
    height: 100%;
    overflow: auto;
    position: fixed;
    transition: 0.5s;
    font-size: $large;
    background: $blue;
    box-shadow: 05px 00px 1px 00px rgba(1, 31, 23, 0.15);

    .side-header {
        background: rgb(255, 255, 255);
        padding: 10px 00px 10px 20px;
        .icon {
            font-size: $large;
            cursor: pointer;
            color: $blue;
        }
        
        .close-icon {
            position: absolute;
            right: 25px;
        }
    }

    .side-contents {
        color: $white;

        .profile-picture {
            box-shadow: 00px 00px 10px 00px $gray;
            background: $white;
            border-radius: 50%;
            position: relative;
            margin: 00px 20px;
            overflow:hidden;
            height: 70px;
            width: 70px;
            float: left;
        
            img {
                transform:translate(-0%, -50%);
                position: absolute;
                max-width: 100%;
                height: auto;
                width: auto;
                top: 60%;
            }
        }
        
        .profile-info {
            grid-template-columns: 1fr;
            padding-top: 15px;
            margin-top: 30px;
            display: grid;

            .profile-name {
                font-size: 15px;
                font-weight: 900;
                margin-bottom: 10px;
            }

            .profile-status {
                font-size: 13px;
                margin-left: 20px;
                position: relative;
            }

            .profile-status::before {
                content: '';
                top: 03px;
                left: -20px;
                width: 10px;
                height: 10px;
                position: absolute;
                border-radius: 50%;
                background: rgb(87, 230, 87);
            }
        }

        .nav-options {
            margin: 20px 00px 50px 00px;

            .head {
                font-size: $medium;
                margin: 50px 10px 20px 10px;
                border-bottom: 1px solid $white;
            }

            .body {
                transition: all 1.5s ease;
                padding: 10px 00px;
                margin: 00px 00px;
                cursor: pointer;

                span {
                    font-size: 15px;
                    margin: 20px 00px 20px 20px;

                    .icon {
                        font-size: 13px;
                        margin-right: 10px;
                    }
                }

                span:active {
                    color: $blueActive;
                }
            }

            .footer {
                border: 1px solid $white;
                transition: all 1.5s ease;
                background: $blue;
                padding: 10px 00px;
                cursor: pointer;
                position: fixed;
                width: 248px;
                bottom: 0;

                span {
                    font-size: 15px;
                    font-weight: 900;
                    margin: 20px 00px 20px 20px;
                    
                    .icon {
                        font-size: 18px;
                        margin-right: 50px;
                    }
                }

                span:active {
                    color: $blueActive;
                }
            }

            .body:hover, .footer:hover, .active {
                background: $white;
                color: $blue;
            }
        }
    }
}

.hamburger-menu {
    padding: 10px 00px 10px 20px;
    position: fixed;
    cursor: pointer;
    width: 50px;
    left: 0;
    top: 0;
   
}