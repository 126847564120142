@use "./size.scss" as *;
@use "./colors.scss" as *;

.view-details {
    .contents {
        min-height: 100vh;
        background-color: $white;
        overflow-x: auto;
        padding: 20px;

        .loan-details {
            .data-header {
                font-size: 20px;
                width: 100%;
                border-bottom: 1px solid #ccc;
                // background-color: #70799c;
                // border-radius: 8px;
                padding: 10px;
                margin-top: 8px;

                .icon {
                    cursor: pointer;
                    font-size: 18px;
                    margin: 0px 10px 0px 0px;
                    // color: #fff;
                }
                span {
                    font-size: 18px;
                    // color: #fff;
                }
            }

            .data-headerEdit {
                font-size: 18px;
                width: 100%;
                border-bottom: 1px solid #ccc;
                // background-color: #70799c;
                // border-radius: 8px;
                padding: 10px;
                margin-top: 8px;
                display: flex;
                justify-content: space-between;

                .icon {
                    cursor: pointer;
                    font-size: 18px;
                    margin: 0px 10px 0px 0px;
                    // color: #fff;
                }
                .iconRight {
                    cursor: pointer;
                    font-size: 24px;
                    margin: 0px 20px 0px 0px;
                    color: #3d4977;
                }
                .buttonRight {
                    cursor: pointer;
                    font-size: 12px;
                    padding: 8px 10px 8px 10px;
                    margin: 0px 10px 0px 0px;
                }
            }

            .add-button {
                margin: auto;
                width: 92%;
                div {
                    border-radius: 50%;
                    background: $blue;
                    cursor: pointer;
                    color: $white;
                    padding: 10px;
                    height: 20px;
                    width: 20px;

                    margin-left: 97%;
                    margin-right: 10%;
                }

                div:active {
                    background: $gray;
                }
            }

            .form-container {
                top: 55%;
                left: 50%;
                width: 40%;
                z-index: 10;
                overflow: auto;
                position: fixed;
                text-align: center;
                background: $white;
                transform: translate(-50%, -50%);
                border-radius: 10px 10px 10px 10px;
                box-shadow: 05px 05px 10px 00px rgba(0, 0, 0, 0.5);

                .form-header {
                    width: 100%;
                    margin: auto;
                    font-size: 15px;
                    overflow: hidden;
                    background: $white;
                    border-bottom: 1px solid #ccc;
                    border-radius: 10px 00px 00px 00px;

                    p {
                        span {
                            border-radius: 50%;
                            background: $white;
                            margin-right: 20px;
                            cursor: pointer;
                            float: right;
                            height: 28px;
                            width: 30px;
                            .icon {
                                color: #3d4977;
                                font-size: 18px;
                            }
                        }
                    }
                }

                .form {
                    padding: 20px 0;
                    text-align: center;
                    // background: $blueActive;

                    input {
                        width: 80%;
                        padding: 10px;
                        outline: none;
                        font-size: 16px;
                        margin: 10px auto;
                        border: 1px solid #ccc;
                    }

                    select {
                        width: 84%;
                        padding: 10px;
                        outline: none;
                        font-size: 16px;
                        margin: 10px auto;
                        border: 1px solid #ccc;
                    }

                    button {
                        width: 35%;
                        color: $white;
                        border: none;
                        padding: 10px;
                        outline: none;
                        cursor: pointer;
                        font-weight: 900;
                        margin: 20px auto;
                        background: #3d4977;
                        font-size: 14px;
                    }

                    button:active {
                        background: #3d4977;
                    }

                    .email-input {
                        border: 0.5px solid rgb(248, 25, 25);
                    }
                    .email-input::placeholder {
                        color: red;
                    }

                    .password-input {
                        border: 0.5px solid rgb(248, 25, 25);
                    }
                    .password-input::placeholder {
                        color: red;
                    }

                    .form-result {
                        color: red;
                        font-size: 15px;
                    }
                }
            }

            .container {
                grid-template-columns: 1fr 1fr;
                display: grid;

                .login-form1,
                .login-form2 {
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;

                    .containerLabel {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }
                    .innercontainerLabel {
                        width: 40%;
                        flex-direction: row;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .innercontainerText {
                        width: 100%;
                        flex-direction: row;
                        display: flex;
                        margin-left: 10px;
                        justify-content: flex-start;
                    }
                    .label {
                        font-size: 14px;
                        display: flex;
                        text-align: right;
                        font-weight: bold;
                        align-content: flex-end;
                    }
                    .text {
                        font-size: 14px;
                        display: flex;
                    }

                    .linktext {
                        font-size: 14px;
                        color: blue;
                        text-decoration: underline;
                        display: flex;
                    }
                    .map {
                        cursor: pointer;
                        font-size: 14px;
                        display: flex;
                        color: blue;
                        text-decoration: underline;
                    }

                    input {
                        width: 100%;
                        // color: $blue;
                        outline: none;
                        font-size: 14px;
                        padding: 05px 10px;
                        border: 1px solid $blue;
                    }

                    .no-edit {
                        flex: 1;
                        border: none;
                        padding: 0px;
                        margin: 0;
                        // background: $background;
                    }
                }
            }

            .update-section {
                width: 92%;
                display: flex;
                margin: 15px auto;
                justify-content: space-between;

                .updateBtn {
                    background-color: $blue;
                    border-radius: 3px;
                    cursor: pointer;
                    color: $white;
                    outline: none;
                    padding: 8px;
                    width: 180px;
                    border: none;
                }

                .updateBtn:active {
                    background: $gray;
                }
            }

            .extra-button {
                text-align: center;
                margin: 10px 10px;
                width: 100%;

                .updateBtn {
                    background-color: #3d4977;
                    border-radius: 3px;
                    padding: 10px 20px;
                    font-weight: 400;
                    cursor: pointer;
                    font-size: 14px;
                    color: $white;
                    margin: 10px;
                    outline: none;
                    border: none;
                }

                .updateBtn:active {
                    background: $gray;
                }
            }

            .delete-section {
                text-align: center;
                margin: 100px 20px;
                width: 100%;

                .deleteBtn {
                    background-color: red;
                    border-radius: 3px;
                    color: $white;
                    outline: none;
                    padding: 8px;
                    width: 500px;
                    border: none;
                }
            }

            .imageVideoTitle {
                font-size: 16px;
                color: #333;
                margin: 10px 10px 0px 10px;
            }
            .divBorder {
                width: 30px;
                height: 3px;
                background-color: #1a89ff;
                margin: 3px 10px 10px 10px;
                border-radius: 1px;
            }

            .image-video-viewer {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                image,
                video {
                    width: 100%;
                    border: none;
                    outline: none;
                    height: 200px;
                    cursor: pointer;
                }
            }

            .button-viewer {
                margin: 100px auto 50px auto;
                text-align: center;
                width: 90%;

                button {
                    border-radius: 3px;
                    padding: 10px 50px;
                    margin: auto 20px;
                    font-weight: 900;
                    cursor: pointer;
                    font-size: 15px;
                    color: $white;
                    outline: none;
                    border: none;
                }
                button:disabled {
                    background: #aaa;
                    cursor: default;
                }

                .verify {
                    background: #3d4977;
                }

                .verify:active {
                    background: $gray;
                }

                .reject {
                    background: $red;
                }

                .reject:active {
                    background: $redActive;
                }
            }
        }

        .construction-details-page {
            .data-header {
                border-top: 1px solid $blueActive;
                margin: 50px auto;
                font-size: 20px;
                width: 100%;

                span {
                    margin: 20px;
                }
            }

            .container {
                grid-template-columns: 1fr 1fr;
                display: grid;

                .login-form1,
                .login-form2 {
                    width: 92%;
                    padding: 20px;

                    .label {
                        color: $blue;
                        font-size: 15px;
                        font-weight: bold;
                        margin: 10px 0px 0px 0px;
                    }

                    input {
                        width: 100%;
                        color: $blue;
                        border: none;
                        font-size: 15px;
                        padding: 05px 10px;
                        background: $background;
                        outline-color: $miniBlue;
                    }

                    .no-edit {
                        border: none;
                        outline: none;
                        background: $background;
                    }
                }
            }
        }

        .request-details-page {
            .data-header {
                font-size: 20px;
                width: 100%;
                border-bottom: 1px solid #ccc;
                // background-color: #70799c;
                // border-radius: 8px;
                padding: 10px;
                margin-top: 8px;

                .icon {
                    cursor: pointer;
                    font-size: 18px;
                    margin: 0px 10px 0px 0px;
                    // color: #fff;
                }
                span {
                    font-size: 18px;
                    // color: #fff;
                }
            }

            .data-headerEdit {
                font-size: 20px;
                width: 100%;
                border-bottom: 1px solid #ccc;
                // background-color: #70799c;
                // border-radius: 8px;
                padding: 10px;
                margin-top: 8px;
                display: flex;
                justify-content: space-between;

                .icon {
                    cursor: pointer;
                    font-size: 18px;
                    margin: 0px 10px 0px 0px;
                    // color: #fff;
                }
                .iconRight {
                    cursor: pointer;
                    font-size: 24px;
                    margin: 0px 20px 0px 0px;
                    color: #3d4977;
                }
                .buttonRight {
                    cursor: pointer;
                    font-size: 14px;
                    margin: 0px 20px 0px 0px;
                }
                span {
                    font-size: 18px;
                    // color: #fff;
                }
            }

            .add-button {
                margin: auto;
                width: 92%;
                div {
                    border-radius: 50%;
                    background: $blue;
                    cursor: pointer;
                    color: $white;
                    padding: 10px;
                    height: 20px;
                    width: 20px;

                    margin-left: 97%;
                    margin-right: 10%;
                }

                div:active {
                    background: $gray;
                }
            }

            .container {
                grid-template-columns: 1fr 1fr;
                display: grid;

                .login-form1,
                .login-form2 {
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;

                    .containerLabel {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: center;
                    }
                    .innercontainerLabel {
                        width: 40%;
                        flex-direction: row;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .innercontainerText {
                        width: 100%;
                        flex-direction: row;
                        display: flex;
                        margin-left: 10px;
                        justify-content: flex-start;
                    }
                    .label {
                        font-size: 14px;
                        display: flex;
                        text-align: right;
                        font-weight: bold;
                        align-content: flex-end;
                    }
                    .text {
                        font-size: 14px;
                        display: flex;
                        max-width: 300px;
                        word-wrap: break-word;
                        flex-wrap: wrap;
                    }
                    .map {
                        cursor: pointer;
                        font-size: 14px;
                        display: flex;
                        color: blue;
                        text-decoration: underline;
                    }

                    input {
                        width: 100%;
                        // color: $blue;
                        outline: none;
                        font-size: 14px;
                        padding: 05px 10px;
                        border: 1px solid $blue;
                    }

                    .no-edit {
                        flex: 1;
                        border: none;
                        padding: 0px;
                        margin: 0;
                        // background: $background;
                    }
                }
            }

            .button-viewer {
                text-align: center;
                width: 100%;

                button {
                    border-radius: 3px;
                    margin: 50px;
                    padding: 10px 50px;
                    background: $blue;
                    font-weight: 900;
                    cursor: pointer;
                    font-size: 15px;
                    color: $white;
                    outline: none;
                    border: none;
                    position: relative;
                }
                button:active {
                    background: $gray;
                }
            }
        }
    }
    .construction-content {
        min-height: 100vh;
        background-color: $white;
        overflow-x: auto;
        .const-plan {
            .data-header {
                font-size: 20px;
                width: 100%;
                border-bottom: 1px solid #ccc;
                // background-color: #70799c;
                // border-radius: 8px;
                padding: 10px;
                margin-top: 8px;

                .icon {
                    cursor: pointer;
                    font-size: 18px;
                    margin: 0px 10px 0px 0px;
                    // color: #fff;
                }
                span {
                    font-size: 18px;
                    // color: #fff;
                }
            }

            .container {
                grid-template-columns: 1fr 1fr;
                display: grid;

                .login-form1,
                .login-form2 {
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;

                    .containerLabel {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }
                    .innercontainerLabel {
                        width: 40%;
                        flex-direction: row;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .innercontainerText {
                        width: 100%;
                        flex-direction: row;
                        display: flex;
                        margin-left: 10px;
                        justify-content: flex-start;
                    }
                    .label {
                        font-size: 14px;
                        display: flex;
                        text-align: right;
                        font-weight: bold;
                        align-content: flex-end;
                    }
                    .text {
                        font-size: 14px;
                        display: flex;
                    }
                    .map {
                        cursor: pointer;
                        font-size: 14px;
                        display: flex;
                        color: blue;
                        text-decoration: underline;
                    }

                    input {
                        width: 100%;
                        // color: $blue;
                        outline: none;
                        font-size: 14px;
                        padding: 05px 10px;
                        border: 1px solid $blue;
                    }

                    .no-edit {
                        flex: 1;
                        border: none;
                        padding: 0px;
                        margin: 0;
                        // background: $background;
                    }
                }
            }

            .update-section {
                width: 92%;
                display: flex;
                margin: 15px auto;
                justify-content: space-between;

                .updateBtn {
                    background-color: $blue;
                    border-radius: 3px;
                    cursor: pointer;
                    color: $white;
                    outline: none;
                    padding: 8px;
                    width: 180px;
                    border: none;
                }

                .updateBtn:active {
                    background: $gray;
                }
            }

            .extra-button {
                text-align: center;
                margin: 10px 10px;
                width: 100%;

                .updateBtn {
                    background-color: #3d4977;
                    border-radius: 3px;
                    padding: 10px 20px;
                    font-weight: 400;
                    cursor: pointer;
                    font-size: 14px;
                    color: $white;
                    outline: none;
                    border: none;
                }

                .updateBtn:active {
                    background: $gray;
                }
            }

            .delete-section {
                text-align: center;
                margin: 100px 20px;
                width: 100%;

                .deleteBtn {
                    background-color: red;
                    border-radius: 3px;
                    color: $white;
                    outline: none;
                    padding: 8px;
                    width: 500px;
                    border: none;
                }
            }

            .imageVideoTitle {
                font-size: 16px;
                color: #333;
                margin: 10px 10px 0px 10px;
            }
            .divBorder {
                width: 30px;
                height: 3px;
                background-color: #1a89ff;
                margin: 3px 10px 10px 10px;
                border-radius: 1px;
            }

            .nocontent {
                width: 98%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 40px;
                padding-bottom: 40px;
                border: 1px solid #ccc;
            }

            .image-video-viewer {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                image,
                video {
                    width: 100%;
                    border: none;
                    outline: none;
                    height: 200px;
                    cursor: pointer;
                }
            }

            .button-viewer {
                margin: 50px 10px 50px 10px;
                text-align: center;

                button {
                    border-radius: 3px;
                    padding: 10px 50px;
                    margin: auto 20px;
                    font-weight: 900;
                    cursor: pointer;
                    font-size: 15px;
                    color: $white;
                    outline: none;
                    border: none;
                }
                button:disabled {
                    background: #aaa;
                    cursor: default;
                }

                .verify {
                    background: #3d4977;
                }

                .verify:active {
                    background: $gray;
                }

                .reject {
                    background: $red;
                }

                .reject:active {
                    background: $redActive;
                }
            }
        }
        .root-container {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    @keyframes modal-video {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes modal-video-inner {
        from {
            transform: translate(0, 100px);
        }
        to {
            transform: translate(0, 0);
        }
    }
    .modal-video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000000;
        cursor: pointer;
        opacity: 1;
        animation-timing-function: ease-out;
        animation-duration: 0.3s;
        animation-name: modal-video;
        -webkit-transition: opacity 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out;
        -ms-transition: opacity 0.3s ease-out;
        -o-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease-out;
    }
    .modal-video-effect-exit {
        opacity: 0;
    }
    .modal-video-effect-exit .modal-video-movie-wrap {
        -webkit-transform: translate(0, 100px);
        -moz-transform: translate(0, 100px);
        -ms-transform: translate(0, 100px);
        -o-transform: translate(0, 100px);
        transform: translate(0, 100px);
    }
    .modal-video-body {
        max-width: 940px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: table;
    }
    .modal-video-inner {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }
    .modal-video-movie-wrap {
        width: 100%;
        height: 0;
        position: relative;
        padding-bottom: 56.25%;
        background-color: #333;
        animation-timing-function: ease-out;
        animation-duration: 0.3s;
        animation-name: modal-video-inner;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        -webkit-transition: -webkit-transform 0.3s ease-out;
        -moz-transition: -moz-transform 0.3s ease-out;
        -ms-transition: -ms-transform 0.3s ease-out;
        -o-transition: -o-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }
    .modal-video-movie-wrap iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .modal-video-close-btn {
        position: absolute;
        z-index: 2;
        top: -35px;
        right: -35px;
        display: inline-block;
        width: 35px;
        height: 35px;
        overflow: hidden;
        border: none;
        background: transparent;
    }
    .modal-video-close-btn:before {
        transform: rotate(45deg);
    }
    .modal-video-close-btn:after {
        transform: rotate(-45deg);
    }
    .modal-video-close-btn:before,
    .modal-video-close-btn:after {
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #fff;
        border-radius: 5px;
        margin-top: -6px;
    }
}
