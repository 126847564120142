@use './assets/style/size.scss' as *;
@use './assets/style/colors.scss' as *;
@use './assets/style/home.scss';
@use './assets/style/login.scss';
@use './assets/style/navbar.scss';
@use './assets/style/sideBar.scss';
@use './assets/style/account.scss';
@use './assets/style/viewDetails.scss';
@use './assets/style/loanMap.scss';

body
{
  margin: 0;
  padding: 0;
  font-size: $large;
  background: $background;
  font-family: 'Lato', sans-serif;
}

.paginate {
  width: $TableHeaderLeftRightPercetange;
  margin: 30px auto;

  .paginate-action {
    margin: 20px auto 50px auto;
      color: $blueActive;
      float: right;

      .paginate-angles {
          font-size: 18px;
          cursor: pointer;
          margin: -10px auto;
          padding: 07px 11px;
          border: 1px solid $gray;
          border-radius: 05px 05px 05px 05px;
        }
        
        .paginate-page {
          color: $white;
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;
          margin: auto 15px;
          padding: 10px 10px;
          background: $addButton;
          border-radius: 05px 05px 05px 05px;
        }
  }
}
  
.Toastify__toast-container {
  width: 380px;
  font-size: 12px;
  font-weight: 900;
  
  .Toastify__toast--info {
    text-align: center;
    color: rgb(199, 199, 199);
    background:  $blue;
  }
  .Toastify__toast--success {
    text-align: center;
    color: rgb(199, 199, 199);
    background:  $blue;
  }
  .Toastify__toast--warning {
    color: rgb(199, 199, 199);
    background: $blue;
  }

  .Toastify__toast--error {
    text-align: center;
    color: $background;
    background: rgb(168, 38, 38);
  }
        
}

.main-loading {
  background: rgba(50, 49, 53, 0.643);
  transform: translate(-50%, -50%);
  position: fixed;
  padding: 100%;
  z-index: 10;
  left: 50%;
  top: 50%;

}

::-webkit-scrollbar { width: 05px; height: 05px; }
::-webkit-scrollbar-thumb { background: $blue; border-radius: 50px; }
::-webkit-scrollbar-track { box-shadow: inset 0 0 50px $background;  }
