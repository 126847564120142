@use "./size.scss" as *;;
@use "./colors.scss" as *;

.users {
    background-color: $white;
    min-height: 91vh;
    .form-container {
        align-self: center;
        left: 50%;
        top: 50%;
        width: 30%;
        overflow: auto;
        position: absolute;
        text-align: center;
        background: $white;
        transform: translate(-50%, -50%);
        border-radius: 10px 10px 10px 10px;
        box-shadow: 05px 05px 10px 00px rgba(0, 0, 0, 0.5);

        .form-header {
            width: 100%;
            margin: auto;
            font-size: 15px;
            overflow: hidden;
            background: $white;
            border-bottom: 1px solid #ccc;
            border-radius: 10px 00px 00px 00px;

            p {
                span {
                    border-radius: 50%;
                    background: $white;
                    margin-right: 20px;
                    cursor: pointer;
                    float: right;
                    height: 28px;
                    width: 30px;
                    .icon {
                        color: #3d4977;
                        font-size: 18px;
                    }
                }
            }
        }

        .form {
            padding: 20px 0;
            text-align: center;
            // background: $blueActive;

            input {
                width: 80%;
                border: none;
                // color: $blue;
                padding: 10px;
                outline: none;
                font-size: 16px;
                margin: 10px auto;
                border: 1px solid #ccc;
            }

            select {
                width: 84%;
                border: none;
                // color: $blue;
                padding: 10px;
                outline: none;
                font-size: 16px;
                margin: 10px auto;
                border: 1px solid #ccc;
            }

            button {
                width: 35%;
                color: $white;
                border: none;
                padding: 10px;
                outline: none;
                cursor: pointer;
                font-weight: 900;
                margin: 20px auto;
                background: #3d4977;
                font-size: 14px;
            }

            button:active {
                background: #3d4977;
            }

            .email-input {
                border: 0.5px solid rgb(248, 25, 25);
            }
            .email-input::placeholder {
                color: red;
            }

            .password-input {
                border: 0.5px solid rgb(248, 25, 25);
            }
            .password-input::placeholder {
                color: red;
            }

            .form-result {
                color: red;
                font-size: 15px;
            }
        }
    }

    .form {
        width: 300;
    }

    .select-fields {
        display: flex;
        justify-content:space-around;
        align-items: center;
    }
    .button {
        margin-top: 32px;
        height: 40px;
    }
}

.customers-account {
    background: $white;
    min-height: 100vh;
}
