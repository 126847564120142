@use "./size.scss" as *;;
@use "./colors.scss" as *;

.home-page {
  .home-container {
    min-height: $commonHeight;
    background-color: $white;
    text-align: center;
    overflow-x: auto;
    padding: 20px;
    
    .header-container {
      justify-content: space-between;
      margin: 50px auto 20px auto;
      display: inline-flex;
      width: 100%;

      .header {
          text-align: left;
          color: $blue;
      }
    }
  }
}